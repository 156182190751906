import styled, { css } from "styled-components"

const unavailable = css`
  border: 2px solid #4b4b4b;
  color: #4b4b4b;
  cursor: auto;

  &:hover {
    border: 2px solid #4b4b4b;
    color: #4b4b4b;
    background: transparent;
    transform: scale(1);
  }
`

const selected = css`
  background: ${props => props.theme.colors.primary.green};
  color: ${props => props.theme.colors.text.darkGrey};
  border: none;
`

const Wrapper = styled.div`
  height: 50px;
  width: 100px;
  border: 2px solid ${props => props.theme.colors.imageBorder.grey};
  text-align: center;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.025);
  }



  ${props => props.unavailable && unavailable}
  ${props => props.selected && selected}
  ${props => props.id === "29" && "grid-column: 2/3;"}
  

  @media (${props => props.theme.mediaQueries.desktop}) {
    padding: 10px;
    height: 90px;
    width: 135px;
  }
     
  @media (min-width: 1440px) {
    ${props => props.id === "29" && "grid-column: auto;"}
  }
`

export default Wrapper
