import styled from "styled-components"

const Container = styled.div`
  display: grid;
  margin: auto;
  grid-gap: 24px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    grid-template-columns: 1fr 1fr;
    max-width: 590px;
    grid-gap: 90px;
  }
`

export default Container
