import styled from "styled-components"

const Form = styled.form`
  display: grid;
  grid-gap: 32px;
  margin: 48px auto 0;
  justify-items: center;
  width: 100%;
`

export default Form
