import styled from "styled-components"

const Input = styled.input`
  display: -webkit-inline-flex;
  width: 250px;
  border: none;
  background: transparent;
  color: ${props => props.theme.colors.text.white};
  border-bottom: 1px solid
    ${props =>
      props.error ? props.theme.colors.red : props.theme.colors.primary.green};
  padding-bottom: 10px;
  font-size: ${props => props.theme.fontSizes.normal};
  font-weight: 600;

  &:focus {
    outline: none;
    border-bottom: 2px solid ${props => props.theme.colors.primary.green};
    padding-bottom: 9px;
  }

  ::placeholder {
    color: "#878787";
  }
  position: relative;

  &[type="password"]::after {
    content: "";
    border-right: 2px solid
      ${props =>
        props.error
          ? props.theme.colors.red
          : props.theme.colors.primary.green};
    border-bottom: 2px solid
      ${props =>
        props.error
          ? props.theme.colors.red
          : props.theme.colors.primary.green};
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    position: absolute;
    right: 0;
    bottom: 3px;
  }
`

export default Input
