import styled from "styled-components"

const Text = styled.p`
  font-size: 20px;
  text-align: center;
  line-height: 36px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    font-size: 22px;
    line-height: 45px;
  }
`

export default Text
