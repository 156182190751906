import styled from "styled-components"
import InputField from "./InputField"

const DateInput = styled(InputField)`
  text-align: center;
  position: relative;
  color: white;

  ::-webkit-datetime-edit-fields-wrapper {
    text-transform: uppercase;
    color: ${props => props.theme.colors.text.white};
  }
  &::-webkit-datetime-edit-day-field:focus {
    background: transparent;
    color: #878787;
  }

  &:invalid::-webkit-datetime-edit-fields-wrapper {
    color: #878787;
  }

  &:valid::-webkit-datetime-edit-day-field,
  &:valid::-webkit-datetime-edit-month-field,
  &:valid::-webkit-datetime-edit-year-field {
    color: white;
  }

  ::placeholder {
    color: #878787;
  }

  ::-webkit-calendar-picker-indicator {
    position: absolute;
    opacity: 0;
    right: 2px;
    z-index: 5;
    background-color: green;
    width: 100%;
  }

  &::after {
    content: "";
    border-right: 2px solid
      ${props =>
        props.error
          ? props.theme.colors.red
          : props.theme.colors.primary.green};
    border-bottom: 2px solid
      ${props =>
        props.error
          ? props.theme.colors.red
          : props.theme.colors.primary.green};
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    position: absolute;
    right: 10px;
    top: 3px;
  }
`

export default DateInput
