import styled from "styled-components"

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.red};
  font-weight: 500;
  line-height: 23px;
  text-align: center;
`

export default ErrorMessage
