import styled from "styled-components"

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 5px;
  margin: 32px auto;
  justify-content: center;
  width: auto;

  @media (min-width: 1440px) {
    grid-template-columns: repeat(${props => (props.second ? 5 : 9)}, auto);
    grid-gap: 5px;
    margin: 32px auto;
    justify-content: center;
    width: auto;
  }
`

export default Container
