import styled from "styled-components"

const Wrapper = styled.div`
  margin-top: 48px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin-top: 64px;
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin-top: 84px;
  }
`

export default Wrapper
