import styled from "styled-components"

const Text = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 5px;
  text-transform: uppercase;
  pointer-events: none;

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin-bottom: 11px;
    font-size: 18px;
  }
`

export default Text
