import styled, { css } from "styled-components"

const small = css`
  padding: 4.5px 12px;
  font-size: 12px;
  height: auto;
  width: auto;
  font-weight: 600;
`

const Button = styled.button`
  height: 50px;
  width: 180px;
  text-transform: uppercase;
  font-size: 18px;
  border: none;
  background: ${props => props.theme.colors.primary.green};
  color: ${props => props.theme.colors.primary.darkGrey};
  cursor: pointer;
  outline: none;
  transition: filter 0.2s ease-in-out;

  ${props => props.small && small}

  &:hover {
    filter: brightness(110%);
  }
`

export default Button
