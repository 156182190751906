import styled from "styled-components"

const Label = styled.label`
  display: block;
  margin-bottom: 4px;
  text-transform: uppercase;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes.small};
`

export default Label
