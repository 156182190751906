import React, { useState } from "react"

//React components imports
import Room from "./Room"

//Styled components imports
import Wrapper from "./Wrapper"
import Container from "./Container"
import Title from "./Title"

const Rooms = props => {
  const [selectedRooms, setSelectedRooms] = useState([])

  const handleClick = e => {
    if (e.target.id !== "") {
      let newRooms = [...selectedRooms]

      if (selectedRooms.includes(e.target.id)) {
        newRooms = newRooms.filter(item => item !== e.target.id)
      } else {
        newRooms.push(e.target.id)
      }

      setSelectedRooms(newRooms)
      props.selectRooms(newRooms.sort())
    }
  }
  // console.log(selectedRooms)
  const renderFirstFloor = () => (
    <Container onClick={handleClick}>
      <Room id="1" apartman selected={selectedRooms} />
      <Room id="2" selected={selectedRooms} />
      <Room id="3" unavailable selected={selectedRooms} />
      <Room id="4" selected={selectedRooms} />
      <Room id="5" selected={selectedRooms} />
      <Room id="6" selected={selectedRooms} />
      <Room id="7" selected={selectedRooms} />
      <Room id="8" selected={selectedRooms} />
      <Room id="9" apartman selected={selectedRooms} />
    </Container>
  )

  const renderSecondFloor = () => (
    <Container second onClick={handleClick}>
      <Room id="20" apartman selected={selectedRooms} />
      <Room id="21" selected={selectedRooms} />
      <Room id="22" selected={selectedRooms} />
      <Room id="23" unavailable selected={selectedRooms} />
      <Room id="24" selected={selectedRooms} />
      <Room id="25" selected={selectedRooms} />
      <Room id="26" selected={selectedRooms} />
      <Room id="27" selected={selectedRooms} />
      <Room id="28" selected={selectedRooms} />
      <Room id="29" apartman selected={selectedRooms} />
    </Container>
  )

  return (
    <Wrapper>
      <Title>1. Poschodie</Title>

      {renderFirstFloor()}
      <h2>2. Poschodie</h2>

      {renderSecondFloor()}
    </Wrapper>
  )
}

export default Rooms
