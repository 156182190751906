import styled from "styled-components"

const FieldError = styled.div`
  color: ${props => props.theme.colors.red};
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes.small};
  position: absolute;
  line-height: 23px;
`

export default FieldError
