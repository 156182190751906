import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import Text from "./Text"
import Telephone from "./Telephone"

const ReservationText = () => {
  return (
    <Wrapper>
      <Text>
        Chvíľku strpenia, pripravujeme pre Vás nový rezervačný systém.<br></br>{" "}
        Ak máte záujem o rezerváciu volajte na tel.{" "}
        <Telephone href="tel:+421903530843">+421 903 530 843</Telephone> .
      </Text>
    </Wrapper>
  )
}

export default ReservationText
