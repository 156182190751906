import React from "react"

//React components imports
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ReservationForm from "../components/ReservationForm"

import ReservationText from "../components/ReservationText"

const Reservation = props => {
  return (
    <Layout>
      {/* <PageTitle>Rezervácia</PageTitle>
      <ReservationForm /> */}
      <ReservationText />
    </Layout>
  )
}

export default Reservation
